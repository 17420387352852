import React from "react";
import { Fragment } from "react";
import { DateTime } from "luxon";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import InvoicesField from "../fields/InvoicesField";
import NumberDisplay from "../fields/NumberDisplay";
import { ExternalLinkIcon } from "@heroicons/react/solid";
import { ExclamationIcon } from "@heroicons/react/outline";
import { ReactComponent as ImportInvoiceImage} from "../../assets/icons8-bill.svg";
import { ReactComponent as CompleteImage } from "../../assets/icons8-tick.svg";
import classNames from "./../utils/classNames";

const api = process.env.REACT_APP_API_ROOT;
const accessToken = new URLSearchParams(document.location.search).get("access_token");

class ImportInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: null,
      imported: false,
      isLoading: false
    };
  }

  setClose = () => {
    this.setState({ invoice: null, imported: false });
    this.props.setClose();
  }

  importInvoice = async () => {
    this.setState({ isLoading: true });

    const response = await fetch(api + "/invoices/import", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accesstoken: accessToken,
      },
      body: JSON.stringify(this.state.invoice)
    });

    const json = await response.json();

    if (json.error) {
      this.props.setError("Error importing invoice");
      this.setState({ invoice: null, isLoading: false });
    } else {
      this.setState({ imported: true, invoice: null, isLoading: false });
    }
  }

  render() {    
    const { invoice } = this.state;

    const currencyMismatch = invoice && this.props.deal?.settings?.currency && invoice.currency !== this.props.deal.settings.currency;
    const contactMismatch = invoice && this.props.deal?.settings?.contact && invoice.contact.id !== this.props.deal.settings.contact;

    const importError = currencyMismatch || contactMismatch;
    const platform = this.props.connectionType === "xero" ? "Xero" : "QuickBooks";
    
    return (
      <>
        <Transition.Root show={this.props.open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-hidden"
            onClose={this.setClose}
            style={this.props.platformStyles}
          >
            <div className="absolute inset-0 overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-0 transition-opacity bg-white bg-opacity-75"> </div>
              </Transition.Child>
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md">
                    <div className="flex flex-col h-full bg-white divide-y divide-gray-200 shadow-xl">
                      <div className="flex flex-col flex-1 min-h-0 py-6 overflow-y-scroll divide-y divide-gray-200">
                        <div className="px-4 sm:px-6 ">
                          <div className="flex items-start justify-between ">
                            <Dialog.Title className="text-xl font-semibold text-gray-900">
                              Import Invoice
                            </Dialog.Title>
                            <div className="flex items-center ml-3 h-7">
                              <button
                                type="button"
                                className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none"
                                onClick={this.setClose}
                              >
                                <span className="sr-only">Close panel</span>
                                <XIcon className="w-6 h-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="relative flex-1 px-6 mt-6 sm:px-6">
                          {this.state.imported ? (
                            <div className="flex flex-col justify-center h-full gap-5 pb-20" >
                              <p className="flex flex-row items-center gap-5 text-lg leading-tight">
                                <CompleteImage className="w-24 h-24" />
                                <div className="flex-grow">
                                  Import successful
                                  <div className="flex flex-row items-center gap-3 py-2 mx-auto">
                                    <div>
                                      <button
                                        onClick={(e) => window.location.reload()}
                                        className="flex justify-center text-sm underline text-platform"
                                      >
                                        Finish Importing
                                      </button>
                                    </div>
                                    <span className="text-sm">or</span>
                                    <div>
                                      <button
                                        className="flex justify-center text-sm underline text-platform"
                                        onClick={(e) => this.setState({ imported: false })}
                                      >
                                        Import again
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </p>
                            </div>
                          ) : (
                            <>
                              <div className="">
                                <p className="flex flex-row items-center gap-5 text-lg leading-tight">
                                  <ImportInvoiceImage className="w-36 h-36" />
                                  <span className="flex-shrink">
                                    Search for an existing {platform} invoice to link to this deal
                                  </span>
                                </p>
                              </div>

                              <label className="block mx-auto w-fit">
                                <span className="text-xs text-grey">
                                  Search {platform} Invoices
                                </span>
                                <InvoicesField
                                  selectInvoice={(invoice) => this.setState({ invoice })}
                                  invoice={this.state.invoice}
                                  connectionType={this.props.connectionType}
                                  platformStyles={this.props.platformStyles}
                                />
                              </label>

                              {invoice && (
                                <>
                                  <div className="p-5 my-6 border rounded-lg">
                                    <h4 className="flex items-center font-semibold">
                                      <div className="flex-1">{invoice.invoiceNumber}</div>
                                      {this.props.shortCode && (
                                        <a
                                          target="_blank"
                                          className="flex items-center flex-shrink gap-1 text-xs font-normal text-platform"
                                          href={`https://go.xero.com/organisationlogin/default.aspx?shortcode=${this.props.shortCode}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${invoice.invoiceId}`}
                                        >
                                          View in Xero
                                          <ExternalLinkIcon className="inline-block w-4 h-4" />
                                        </a>
                                      )}
                                    </h4>

                                    <div className="flex flex-col gap-2">
                                      {contactMismatch && (
                                        <div className="flex items-center gap-2 px-2 py-1 text-xs text-red-900 bg-red-200 border border-red-900 rounded-md">
                                          <ExclamationIcon className="inline-block w-5 h-5" /> This
                                          doesn't match the contact on this deal
                                        </div>
                                      )}
                                      {currencyMismatch && (
                                        <div className="flex items-center gap-2 px-2 py-1 text-xs text-red-900 bg-red-200 border border-red-900 rounded-md">
                                          <ExclamationIcon className="inline-block w-5 h-5" /> This
                                          doesn't match the currency on this deal
                                        </div>
                                      )}
                                    </div>
                                    <dl className="py-3 text-sm leading-6 divide-y divide-gray-100">
                                      <div className="flex justify-between py-1 gap-x-4">
                                        <dt className="text-grey">Total</dt>
                                        <dd>
                                          <NumberDisplay
                                            value={invoice.total}
                                            currency={invoice.currency}
                                          />
                                        </dd>
                                      </div>
                                      <div className="flex justify-between py-1 gap-x-4">
                                        <dt className="text-grey">Date</dt>
                                        <dd>
                                          {DateTime.fromISO(invoice.date).toLocaleString(
                                            DateTime.DATE_SHORT
                                          )}
                                        </dd>
                                      </div>
                                      {this.props.connectionType === "xero" && (
                                        <div className="flex justify-between py-1 gap-x-4">
                                          <dt className="text-grey">Reference</dt>
                                          <dd>{invoice.reference}</dd>
                                        </div>
                                      )}
                                      <div className="flex justify-between py-1 gap-x-4">
                                        <dt className="text-grey">Status</dt>
                                        <dd className="capitalize">
                                          {invoice.status.toLowerCase()}
                                        </dd>
                                      </div>
                                      <div className="flex justify-between py-1 gap-x-4">
                                        <dt className="text-grey">Contact</dt>
                                        <dd>{invoice.contact.name}</dd>
                                      </div>
                                    </dl>
                                  </div>
                                  {invoice.exists ? (
                                    <div className="flex items-center gap-2 mb-2 px-2 py-1 text-xs text-red-900 bg-red-200 border border-red-900 rounded-md">
                                      <ExclamationIcon className="inline-block w-5 h-5" /> This
                                      invoice is already linked to a deal. It will be removed from the previous deal.
                                    </div>
                                  ) : null}
                                  <div>
                                    <button
                                      onClick={this.importInvoice}
                                      className={classNames(
                                        importError ? "!bg-gray-300 pointer-events-none" : "",
                                        "px-6 py-3 block text-white rounded-md border-platform  mx-auto bg-platform"
                                      )}
                                      disabled={importError || this.state.isLoading}
                                    >
                                      {this.state.isLoading ? "Importing..." : "Import Invoice"}
                                    </button>
                                  </div>
                                </>
                              )}
                              <p className="mt-10 text-xs text-grey">
                                Looking to import in bulk? We can help! Just{" "}
                                <a
                                  className="text-primary"
                                  href={process.env.REACT_APP_SUPPORT_LINK}
                                  target="_blank"
                                >
                                  contact the support team
                                </a>
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default ImportInvoice;
