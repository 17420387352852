/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Puff } from "svg-loaders-react";
import classNames from "../utils/classNames";

import { ReactComponent as CheckIcon } from "../../assets/iconmonstr-check-mark-circle-lined.svg";

export default function Syncing({
  open,
  saveStatus,
  syncStatus,
  attachmentsCount,
  attachmentsSyncingCount,
  attachmentsErrorCount,
  setClose,
  platformStyles,
}) {

  const statusIcon = (status) => {
    switch (status) {
      case "in_progress":
        return <Puff className="w-5 h-5" stroke="#16a34a" strokeOpacity="1" />;
      case "error":
        return <XIcon className="w-5 h-5" />;
      default:
        return <CheckIcon className="w-6 h-6 p-1 text-green-600 bg-green-300 rounded-full" />;
    }
  }

  const uploadStatus =  attachmentsSyncingCount > 0 || syncStatus !== "complete" ? "in_progress" : attachmentsErrorCount > 0 ? "error" : "complete";

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={setClose}
        style={platformStyles}
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block px-6 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-8">
              <div className="flex flex-col gap-5">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-center">
                  {syncStatus ? "Syncing invoices" : "Saving invoices"}
                </Dialog.Title>

                <div className="flex flex-col gap-5">
                  <div className="flex flex-row items-center gap-5 px-5 py-2 bg-gray-100 rounded-lg">
                    {statusIcon(saveStatus)}
                    {saveStatus === "in_progress" && "Saving invoices..."}
                    {saveStatus === "complete" && "Save complete"}
                    {saveStatus === "error" && "Save error - close to see details"}
                  </div>

                  {syncStatus ? (
                    <div
                      className={classNames(
                        saveStatus !== "complete" && "opacity-50",
                        "flex flex-row items-center gap-5 px-5 py-2 bg-gray-100 rounded-lg"
                      )}
                    >
                      {statusIcon(!saveStatus === "complete" ? "in_progress" : syncStatus)}
                      {!saveStatus === "complete" && "Waiting..."}
                      {syncStatus === "in_progress" && "Syncing invoices..."}
                      {syncStatus === "complete" && "Sync complete"}
                      {syncStatus === "error" && "Sync error - close to see details"}
                    </div>
                  ) : null}

                  {syncStatus && attachmentsCount > 0 ? (
                    <div
                      className={classNames(
                        saveStatus === "complete" && syncStatus === "complete" && "!opacity-100",
                        "flex flex-row items-center gap-5 px-5 py-2 bg-gray-100 rounded-lg opacity-50"
                      )}
                    >
                      {statusIcon(uploadStatus)}
                      {saveStatus !== "complete" || syncStatus !== "complete"
                        ? "Waiting..."
                        : uploadStatus === "in_progress"
                        ? `Uploading attachment ${
                            attachmentsCount - attachmentsSyncingCount + 1
                          } of ${attachmentsCount}`
                        : uploadStatus === "complete"
                        ? "Upload complete"
                        : uploadStatus === "error"
                        ? `Upload error - ${
                            attachmentsCount - attachmentsErrorCount
                          } of ${attachmentsCount} successful`
                        : null}
                    </div>
                  ) : null}
                </div>
                {syncStatus === "complete" && attachmentsSyncingCount > 0 ? (
                  <div className="text-xs text-grey">
                    Attachments will upload in the background - you can close this window if you
                    don't want to wait
                  </div>
                ) : null}

                <div className="text-center ">
                  <button
                    type="button"
                    className="px-6 py-3 text-white rounded-md bg-platform"
                    disabled={saveStatus === "in_progress" || syncStatus === "in_progress"}
                    onClick={() => setClose()}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
